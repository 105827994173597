import xhr from './xhr/';
/**
 * 打卡相关的 API
 */
class PunchService {
	// 打卡地址
	getPunchAddresses() {
		return xhr({
			method: 'get',
			url: '/services/dkdz',
		});
	}

	// 打卡列表
	getPunchList(data) {
		return xhr({
			method: 'get',
			url: '/services/dkjl',
			params: data,
		});
	}

	// 打卡统计
	getPunchStatistics(data) {
		return xhr({
			method: 'get',
			url: '/services/dktj',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new PunchService();
